@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/montserrat');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~nebular-icons/css/nebular-icons.css';
@import '~@nebular/auth/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

nb-select.appearance-outline.status-basic .select-button {
  background-color: white;
}

html *, body *, button * {
  font-family: 'Montserrat', sans-serif !important;
}

.mat-cell, .mat-header-cell {
  padding: 1rem .5rem !important;
}

.mat-dialog-container {
  padding: 0 !important;
}
